import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import {
  TableFilter,
  InputSelect,
  FilterDropdown,
  InputMultiselect,
  FilterButton,
} from "../";
import { useSearchStageByNameQuery } from "../../store/services/sss.api";
import { FilterIcon, GridIcon } from "../../assets";
import StageTableSelected from "./StageTableSelected";
import {
  optionsExchange,
  SCROLL_THRESHOLD,
  PAGE_SIZE,
} from "../../utils/consts";
import ClientFilterInput from "../ClientFilterInput";

const initialFilters = {
  selectedStages: [],
  selectedClients: [],
  selectedExchange: "",
};

const StageTableFilter = ({
  setFilterStage,
  setFilterClient,
  setFilterExchange,
  cells,
  setCellExclusion,
  excludedCells,
  selectedRows = [],
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const toggleFilter = useCallback(
    () => setOpenFilter((prevState) => !prevState),
    [],
  );

  const [queryStage, setQueryStage] = useState("");
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  const { data, isFetching, isLoading } = useSearchStageByNameQuery({
    name: queryStage,
    offset,
  });

  const onSearchStage = useCallback((value) => {
    setOffset(0);
    setQueryStage(value || "");
  }, []);

  const onSelectStage = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedStages: value || [],
      })),
    [],
  );

  const onSelectClient = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedClients: value || [],
      })),
    [],
  );

  const onSelectExchange = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedExchange: value?.name || "",
      })),
    [],
  );

  const optionsStage = useMemo(
    () =>
      data?.rows?.map((item) => ({
        label: item.name,
        ...item,
      })) || [],
    [data],
  );

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + SCROLL_THRESHOLD;

    if (bottom && data?.isNextPage && !isFetching) {
      setOffset((prevOffset) => prevOffset + PAGE_SIZE);
    }
  };

  const applyFilters = useCallback(() => {
    setFilterStage(filters.selectedStages);
    setFilterClient(filters.selectedClients);
    setFilterExchange(filters.selectedExchange);
  }, [filters, setFilterStage, setFilterClient, setFilterExchange]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setFilterStage([]);
    setFilterClient([]);
    setFilterExchange("");
  }, [setFilterStage, setFilterClient, setFilterExchange]);

  const getFilterExchange = useCallback(() => {
    if (filters.selectedExchange) {
      return optionsExchange.find((o) => o.name === filters.selectedExchange)
        ?.label;
    }
    return null;
  }, [filters]);

  return (
    <>
      <TableFilter>
        <StageTableSelected selectedRows={selectedRows} />
        <Grid item>
          <FilterButton
            onClick={toggleFilter}
            icon={<img src={FilterIcon} alt="checkbox" />}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            getSelected={setCellExclusion}
            excludedCells={excludedCells}
            options={cells}
            icon={<img src={GridIcon} alt="grid icon" />}
          />
        </Grid>
      </TableFilter>
      <TableFilter
        style={{ borderRadius: 0, display: openFilter ? "flex" : "none" }}
      >
        <Grid item>
          <InputMultiselect
            loading={isLoading || isFetching}
            options={optionsStage}
            label={"Stage"}
            value={filters.selectedStages}
            handleInputChange={onSearchStage}
            getSelected={onSelectStage}
            listboxProps={{
              onScroll: handleScroll,
              style: {
                maxHeight: "550px",
              },
            }}
          />
        </Grid>
        <Grid item>
          <ClientFilterInput
            value={filters.selectedClients}
            getSelected={onSelectClient}
          />
        </Grid>
        <Grid item>
          <InputSelect
            options={optionsExchange}
            label={"Exchange"}
            defaultValue={null}
            value={getFilterExchange()}
            getSelected={onSelectExchange}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button variant="outlined" onClick={clearFilters}>
            Clear All
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={applyFilters}>
            Apply
          </Button>
        </Grid>
      </TableFilter>
    </>
  );
};

StageTableFilter.propTypes = {
  setFilterStage: PropTypes.func.isRequired,
  setFilterClient: PropTypes.func.isRequired,
  setFilterExchange: PropTypes.func.isRequired,
  cells: PropTypes.array.isRequired,
  setCellExclusion: PropTypes.func.isRequired,
  excludedCells: PropTypes.array.isRequired,
  selectedRows: PropTypes.array,
};

export default StageTableFilter;
