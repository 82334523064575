import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PAGINATION_LIMIT } from "../../utils/consts";
import { compareObjects } from "../../utils/compare";
import { buildQueryString } from "../../utils/buildQueryString";

const mergePaginationData = (currentCache, newItems, { arg }) => {
  if (arg.offset === 0) {
    return {
      rows: newItems.rows,
      isNextPage: newItems.isNextPage,
    };
  }
  return {
    rows: [...currentCache.rows, ...newItems.rows],
    isNextPage: newItems.isNextPage,
  };
};

export const sssApi = createApi({
  reducerPath: "sssApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");

      const token = getState().auth.token.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStagesList: builder.query({
      query: ({
        limit = PAGINATION_LIMIT,
        offset = 0,
        stage = [],
        exchange = "",
        trading = "",
        client = [],
        orderBy = "name",
      }) => {
        const filters = [
          exchange && `exchange=${exchange}`,
          trading && `trading_status=${trading}`,
          client.length && client.map((i) => `client_id=${i}`).join("&"),
          stage.length && stage.map((i) => `stage_id=${i}`).join("&"),
          orderBy && `order_by=${orderBy}`,
        ]
          .filter(Boolean)
          .join("&");

        return `/sss/stages?limit=${limit}&offset=${offset}${filters ? `&${filters}` : ""}`;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        !compareObjects(currentArg, previousArg),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: mergePaginationData,
      transformResponse: (response) => ({
        rows: response,
        isNextPage: response.length > 0,
      }),
      providesTags: ["StageList"],
    }),

    getStagesById: builder.query({
      query: (id) => `/sss/stages/${id}`,
      providesTags: ["Stage"],
    }),

    getStageHistoryById: builder.query({
      query: ({
        id,
        limit = PAGINATION_LIMIT,
        offset = 0,
        orderBy,
        startDate,
        endDate,
      }) => {
        const queryString = buildQueryString({
          limit,
          offset,
          order_by: orderBy,
          range_from: startDate,
          range_to: endDate,
        });

        return `/sss/stages/${id}/transaction-history?${queryString}`;
      },

      forceRefetch: ({ currentArg, previousArg }) =>
        !compareObjects(currentArg, previousArg),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: mergePaginationData,
      transformResponse: (response) => ({
        rows: response,
        isNextPage: response.length > 0,
      }),
      providesTags: ["TransactionHistoryList"],
    }),

    bulkUpdateStageStatus: builder.mutation({
      query: (body) => ({
        url: `/sss/stages/change-status`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["StageList"],
    }),

    createStage: builder.mutation({
      query: (body) => ({
        url: "/sss/stages",
        method: "POST",
        body: JSON.stringify(body),
      }),
      invalidatesTags: ["StageList"],
    }),

    deleteStageById: builder.mutation({
      query: (id) => ({
        url: `/sss/stages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["StageList"],
    }),

    updateStageById: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/sss/stages/${id}`,
        method: "PUT",
        body: JSON.stringify(body),
      }),
      invalidatesTags: ["Stage"],
    }),

    checkConnectionStatus: builder.mutation({
      query: ({ id }) => ({
        url: `/sss/stages/${id}/check-and-sync`,
        method: "POST",
      }),
      invalidatesTags: ["StageList"],
    }),

    updateExchangeApiKey: builder.mutation({
      query: (body) => ({
        url: `/sss/stages/${body.id}/exchange-api-keys`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Stage"],
    }),

    searchStageByName: builder.query({
      query: ({ name = "", offset = 0 }) =>
        `/sss/stages?${buildQueryString({ limit: 50, name, offset })}`,
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: mergePaginationData,
      transformResponse: (response) => ({
        rows: response,
        isNextPage: response.length > 0,
      }),
    }),
  }),
});

export const {
  useGetStagesListQuery,
  useGetStagesByIdQuery,
  useGetStageHistoryByIdQuery,
  useCreateStageMutation,
  useDeleteStageByIdMutation,
  useUpdateStageByIdMutation,
  useCheckConnectionStatusMutation,
  useSearchStageByNameQuery,
  useBulkUpdateStageStatusMutation,
  useUpdateExchangeApiKeyMutation,
} = sssApi;
